import { useLocation } from "react-router-dom";
import css from "../css/contact.module.css";
import { Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const Contact = () => {
  let location = useLocation();
  location = location.pathname.split("/");
  let path = "/" + location[location.length - 1];
  path = path.charAt(1);

  const openings = [
    {
      id: "bim",
      title: "Business Intelligence Manager",
    },
  ];

  const job = openings.find((opening) => opening.id === path);
  return (
    <div>
      <div className={css.background}>
        <div className="container">
          <div className={css.contactContent}>
            <div className={css.contact}>
              <Typography
                variant="p"
                component="p"
                fontWeight="bold"
                sx={{ fontSize: { xs: "12px", sm: "12px", md: "14px" } }}
              >
                Fill in the Form
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
              >
                Application For <br /> {job && job?.title}
              </Typography>
              <div className={css.location}>
                <div className={css.locationItem}>
                  <LocalPhoneIcon className={css.itemIcon} />
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: { xs: "12px", sm: "12px", md: "13px" } }}
                  >
                    +234816202489
                  </Typography>
                </div>
                <div className={css.locationItem}>
                  <LocalPostOfficeIcon className={css.itemIcon} />
                  <Typography
                    variant="p"
                    component="p"
                    fontWeight="bold"
                    sx={{ fontSize: { xs: "12px", sm: "12px", md: "13px" } }}
                  >
                    support@moohub.tech
                  </Typography>
                </div>
                <div className={css.locationItem}>
                  <LocationOnIcon className={css.itemIcon} />
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: { xs: "12px", sm: "12px", md: "13px" } }}
                  >
                    Bamboo plaza Annex, Ifo local Government, BPX 201, Noble Cl,
                    Berger Lagos
                  </Typography>
                </div>
              </div>
            </div>
            <div className={css.contactForm}>
              <form>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Full Name</label> <br />
                  <input
                    type="text"
                    id="my-input"
                    placeholder="Joy John"
                    aria-describedby="my-helper-text"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Email address</label> <br />
                  <input
                    type="email"
                    id="email"
                    placeholder="name@domain"
                    aria-describedby="my-helper-text"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Country</label> <br />
                  <input type="text" id="country" />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Experience</label> <br />
                  <input
                    type="text"
                    id="country"
                    placeholder="Experience in years"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Salary Expectation</label> <br />
                  <input
                    type="text"
                    id="salary"
                    placeholder="Salary expectation in Naira"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="resume">Upload Resume</label> <br />
                  <input type="file" id="resume" />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">
                    What makes you a good for for this position?
                  </label>{" "}
                  <br />
                  <textarea placeholder="Convince us..." />
                </div>
                <div>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
