import css from "./card.module.css";

const Card = (props) => {
  return (
    <div
      style={{
        width: props.width && { lg: props.width, md: props.width, sm: "2rem" },
        height: props.height && props.height,
      }}
      className={css.card}
    >
      {props.children}
    </div>
  );
};

export default Card;
