import css from "./who-we-are.module.css";
import { Typography } from "@mui/material";
import { AttentionSeeker, Slide } from "react-awesome-reveal";

const WhoWeAre = () => {
  return (
    <div className="container">
      <div className={css.flexCenter}>
        <Typography className={css.header} fontWeight="bold" variant="h4">
          Who We Are
        </Typography>
        <AttentionSeeker effect="pulse">
          <Typography variant="body2" sx={{ lineHeight: "2rem" }}>
            We are a customer-oriented, user-centric technological startup
            company with a vision to be the most valuable tech solution company
            in the world, by providing valuable, accessible technology solutions
            to individuals, businesses and more. Our goal is to integrate
            metaverse solutions in problem solving, using the latest
            advancements in the industry.
          </Typography>
        </AttentionSeeker>
        <div id="services"></div>
      </div>
    </div>
  );
};

export default WhoWeAre;
