import { Typography } from "@mui/material";

const SnappPrivacyPolicy = () => {
  return (
    <div className="container" style={{ marginTop: "5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4">Safe Naija App Privacy Policy</Typography>
      </div>
      <div>
        <p>
          Written below is the Privacy Policy for SafeNaija mobile application,
          effective from the Date of Launch for the SafeNaija Application.
          MOOHUB Technologies ("Us", "We", or "Our") operates the SafeNaija
          mobile application (hereinafter referred to as the "Service"), offered
          to every customer using the Service (hereinafter referred to as "You"
          or "Your"). This page informs you of our policy regarding the
          collection, use, and disclosure of personal data when you use our
          Service and the choices you have associated with that data. We use
          your data to provide and improve the Service. We reserve the right to
          modify this policy at any time, and any changes will be reflected in
          an updated version of this Privacy Policy. By using the Service, you
          acknowledge that <strong>you have read and understood</strong> our
          Privacy Policy and <strong>consent to our collection</strong>, use,
          and disclosure of your personal information as described in accordance
          with this policy.
        </p>
        <p>
          <h4>INFORMATION COLLECTION AND USE </h4>
          To provide and improve our Service to you, we collect and store
          different types of data and information from you. These include, but
          are not limited to:
          <ol style={{ listStyle: "lower-alpha" }}>
            <li>
              Personal Data: While using our Service, we may ask you to provide
              us with certain personally identifiable information that can be
              used to contact or recognise you (hereinafter referred to as
              "Personal Data"). Personally identifiable information may include,
              but is not limited to your:
              <ul>
                <li>Email address </li>
                <li>First and last names, nicknames, and gender</li>
                <li>Date of Birth and facial data</li>
                <li>Address, State, ZIP/Postal code, City Phone number</li>
              </ul>
            </li>
            We are aware that your Personal Data is private and as such must be
            treated with all confidentiality, and you can be assured that it is
            treated as such. Collection of your personal data is used mainly for
            the sole purpose of setting up your personal profile and as a
            creation of your unique ID as a user of the Service.
            <li>
              Usage Data: To continuously improve user experience and optimise
              app features, we may also collect information about how the
              Service is accessed and used ("Usage Data" or "App Usage Data").
              This Usage Data may include information such as your device's
              Internet Protocol address (e.g. IP address and MAC address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data. We can
              assure you that your App Usage Data shall only be used for the
              optimisation of your interaction with the app and to better your
              experience while using our Service
            </li>
            <li>
              Emergency Contact Information: The Service involves the
              registration of some certain people on your contact list who you
              consider and we refer to as Trusted Emergency Contacts. In order
              to connect users to these Trusted Contacts, we may ask for contact
              information such as phone numbers, email addresses, or other
              relevant information about these contacts ("Emergency Contact
              Information").
            </li>
          </ol>
        </p>
        <p>
          <h4>INCIDENT REPORTS</h4>
          Our application allows users to report incidents such as theft,
          assault, or other criminal activities. When a user submits an incident
          report, we collect the following information:
          <ul>
            <li>Location of the incident</li>
            <li>Time and date of the incident</li>
            <li>Description of the incident (optional)</li>
            <li>
              Any media data (image(s) or video(s)) submitted by the user.
            </li>
          </ul>
          Please note that our application does not guarantee a response or
          resolution to reported incidents. Users have the responsibility to
          ensure that the information provided in the incident report is
          accurate and complete to the best of their knowledge.
        </p>
        <p>
          <h4>DISCLOSURE OF INFORMATION TO AUTHORITIES</h4>
          Unless required by law, we will not disclose user personal information
          if it comes at the detriment of said user. However, we may disclose
          user information to local authorities or other third parties if
          required by law or if we believe that it is necessary to prevent harm
          to an individual or the public. This may include the following
          situations:
          <ul>
            <li>
              If disclosure of user information is pivotal in the ensuring of
              the safety and security of a user
            </li>
            <li>
              If we believe that disclosure is necessary to prevent harm to an
              individual or the public, such as in cases of suspected fraud,
              cyber-attack, or other illegal activities
            </li>
            <li>
              If we believe that disclosure is necessary to protect our rights,
              property, or safety, or the rights, property, or safety of our
              users or others, or
            </li>
            <li>
              If we receive a valid request from a law enforcement agency,
              regulatory body, or other government authority.
            </li>
          </ul>
          We may also share user information with third-party service providers
          who assist us in providing our services. These service providers are
          contractually obligated to protect the confidentiality and security of
          user information and are prohibited from using the information for any
          other purpose. We take reasonable measures to ensure that any
          disclosure of user information is lawful and necessary, and we will
          contest any and all invalid or overbroad requests for user
          information.
        </p>
        <p>
          <h4>EMERGENCY CONTACT INFORMATION</h4>
          Our application allows users to register emergency contacts who can be
          notified in case of an emergency. When a user registers an emergency
          contact, we collect the following information:
          <ul>
            <li>Name of the contact</li>
            <li> Phone number of the contact</li>
            <li> Relationship of the contact to the user</li>
          </ul>
          We use this information to notify the user's emergency contacts in
          case of an emergency. We will not use this information for any other
          purpose without the user's consent. We take reasonable measures to
          protect the confidentiality and security of information given to us,
          including emergency contact information, but you have the
          responsibility to ensure that the emergency contact information
          provided is accurate and up-to-date. You may update or delete your
          emergency contact information at any time through the application.
        </p>
        <p>
          <h4> DATA SECURITY</h4>
        </p>
        We take reasonable measures to protect the confidentiality and security
        of user information, including incident reports and emergency contact
        information, from unauthorized access or disclosure. This includes the
        following measures:
        <ul>
          <li>
            {" "}
            Encrypting user information when it is transmitted over the internet
            or stored in our systems
          </li>
          <li>
            Implementing access controls and other technical and organizational
            measures to prevent unauthorized access to user information, and{" "}
          </li>
          <li>
            Monitoring our systems for security vulnerabilities and responding
            promptly to security incidents.
          </li>
        </ul>
        Please note that while we take reasonable measures to protect user
        information, no method of transmission or storage can be completely
        secure. As much as we try to guarantee the security of user information,
        we are not responsible for unauthorized access to user information that
        is beyond our control or as a result of information leakage on the
        user's end. Users have the responsibility to keep their login
        credentials, such as usernames and passwords, confidential and secure.
        <p>
          Users should not share their login credentials with others, and should
          notify us immediately if they suspect that their account has been
          compromised.
        </p>
        <p>
          <h4> RETENTION AND DELETION OF DATA</h4>
          We retain user information, including incident reports and emergency
          contact information, for as long as necessary to provide our services
          and to comply with legal and regulatory obligations. This may include
          the following retention periods:
          <ul>
            <li>
              Incident reports and emergency contact information: for up to 1
              year after the user's last use of our services
            </li>
            <li>
              Other user information, such as account information: for as long
              as the user's account is active or as needed to provide our
              services
            </li>
          </ul>
          After the end of the retention period, we will delete or anonymize
          user information in accordance with our Data Retention and Deletion
          Policies. Users may request that we delete their user information at
          any time. We will comply with such requests after confirming the
          identity of the user, unless we are required by law to retain the
          information or unless there are legitimate business reasons for us to
          retain the information. We may also retain certain information for
          backup or archival purposes, to prevent fraud or abuse, or as
          otherwise required by law.
        </p>
        <p>
          <h4>DATA SHARING</h4>
          We do not share user information, including incident reports and
          emergency contact information, with third parties for their own
          marketing purposes. In the event that user data be required for
          marketing use, users shall be notified prior to use of content. All
          media files, e.g. audio and video recordings and images, uploaded
          through the Service, are bound by copyright law under us, and we
          reserve that right.
          <p>
            We may share user information with third-party service providers who
            perform services on our behalf, such as hosting, data analysis, and
            customer support. These service providers are authorized to use user
            information only as necessary to perform their services for us and
            are contractually obligated to keep user information confidential
            and secure.
          </p>
          <p>
            We may also share user information with local authorities or other
            third parties as necessary to provide our services, to comply with
            legal and regulatory obligations, or to prevent harm to an
            individual or the public.
          </p>
          <p>
            We may or may not also share user information in connection with a
            merger, acquisition, or sale of all or a portion of our assets,
            depending on the level of involvement of the sale or acquisition. In
            the event that such an acquisition requires the sharing of personal
            data, all users on the network shall be notified prior.
          </p>
          <p>
            If we receive a request for user information from a government or
            law enforcement agency, we will review the request to ensure that it
            is valid and legally sufficient. We will comply with the request
            only to the extent required by law and will notify the user of the
            request unless we are prohibited by law from doing so.
          </p>
        </p>
        <p>
          <h4>USER DATA POLICY</h4>
          Users have certain rights with respect to their personal information,
          including incident reports and emergency contact information. These
          rights may include the following:
          <ul>
            <li>
              Access: Users may request access to the personal information we
              hold about them.
            </li>
            <li>
              Rectification: Users may request that we correct or update any
              inaccurate or incomplete personal information.
            </li>
            <li>
              Erasure: Users may request that we delete their personal
              information, subject to our data retention and deletion policies
            </li>
            <li>
              Objection: Users may object to the processing of their personal
              information on certain grounds, such as for direct marketing
              purposes.
            </li>
            <li>
              Restriction: Users may request that we limit the processing of
              their personal information in certain circumstances, as
              permissible by law.
            </li>
            <li>
              Portability: Users may request a copy of their personal
              information in a structured, commonly used, and machine-readable
              format.
            </li>
          </ul>
          Users may exercise these rights by contacting us at
          <a
            style={{
              textDecoration: "none",
              paddingLeft: "3px",
              paddingRight: "3px",
            }}
            href="mailto:support@moohub.tech"
          >
            support@moohub.tech.
          </a>
          We may request additional information from the user to verify their
          identity before fulfilling the request. We will respond to user
          requests in a timely and appropriate manner, subject to our legal and
          regulatory obligations. If we are unable to comply with a user
          request, we will respond to such request with a reason for our
          inability to do so.
          <p>
            We take the privacy and security of our users' personal information
            seriously and are committed to protecting it. We have implemented
            technical, administrative, and physical security measures to
            safeguard user information against unauthorized access, disclosure,
            alteration, or destruction. Our Privacy Policy outlines the types of
            information we collect, how we use it, how we protect it, and the
            rights and choices users have with respect to their personal
            information. If you have any questions or concerns regarding our
            Privacy Policy, please contact us at
            <a
              style={{
                textDecoration: "none",
                paddingLeft: "3px",
                paddingRight: "3px",
              }}
              href="mailto:support@moohub.tech"
            >
              support@moohub.tech.
            </a>
          </p>
        </p>
      </div>
    </div>
  );
};

export default SnappPrivacyPolicy;
