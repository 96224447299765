import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import css from "../css/home.module.css";
import { Fade } from "react-awesome-reveal";
import { AttentionSeeker } from "react-awesome-reveal";

// Components
import WhoWeAre from "../components/who-we-are/WhoWeAre";
import OurServices from "../components/our-services/OurServices";
import Loader from "../components/loader/Loader";
import OurClients from "../components/our-clients/OurClients";
import WhyChooseUs from "../components/why-choose-us/WhyChooseUs";
import SetApart from "../components/set-apart-section/SetApart";
import DevelopmentProcess from "../components/development-process/DevelopmentProcess";

// import TestimonialCard from "../components/testimonial-card/TestimonialCard";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  });
  return (
    <>
      {loading && <Loader />}
      <div className={css.homeHero}>
        <div className={css.heroOverlay}>
          <div className="container">
            <Fade cascade>
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "1.4rem", sm: "1.7rem", md: "3rem" },
                  mx: "1rem",
                  mt: "10rem",
                }}
              >
                Actualizing Solutions
                <br />
                with Technology
              </Typography>
            </Fade>
            <Typography
              variant="body2"
              component="h6"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "14px" },
                mx: "1rem",
                width: "60%",
              }}
            >
              You conceive the ideas, we make it a reality. You've got the
              business, we make it an empire! Our process is an industry
              standard. Our services, perfecto!
            </Typography>
          </div>
        </div>
      </div>

      <div className={css.aboutSection}>
        <div className="container">
          <div className={css.about}>
            <AttentionSeeker effect="headShake">
              <img
                loading="lazy"
                src="/images/about/about.png"
                className={css.img}
                alt="About"
              />
            </AttentionSeeker>
            <div className={css.aboutItem + " " + css.aboutText}>
              <div>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  The Number One Metaverse Technology Company In Nigeria
                </Typography>

                <Typography sx={{ lineHeight: "2rem" }} variant="body2">
                  MooHub is a technological startup company with a vision to be
                  the most valuable technological solution company in the world.
                  Our solutions include Metaverse products (web 3.0), Web 2.0,
                  games and applications for multiple platforms such as Android,
                  iOS, MacOS and Windows as well as other technology-related
                  services.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhoWeAre />
      <OurServices />
      {/* <WhyChooseUs /> */}
      <SetApart />
      <DevelopmentProcess />
      <OurClients />
      {/* <TestimonialCard /> */}
    </>
  );
};

export default Home;
