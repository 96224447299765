import { Typography } from "@mui/material";
import css from "./set-apart.module.css";

import React from "react";

const SetApart = () => {
  return (
    <>
      <section className={css.container}>
        <div className="container">
          <Typography
            className={css.letterSpaced}
            variant="h6"
            sx={{ ml: "2rem" }}
          >
            WHAT SETS US
          </Typography>
          <Typography
            className={css.letterSpaced}
            variant="h6"
            sx={{ color: "#000080", fontWeight: "bold", ml: "2rem" }}
          >
            APART
          </Typography>
          <div className={css.items}>
            <div className={css.item}>
              <img
                src="/images/home/expertise.png"
                alt="Expertise and leadership"
              />
              <Typography variant="body1" className={css.title}>
                Expertise & Leadership
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={css.description}
              >
                With years of industry experience and a team of skilled
                professionals, we bring a wealth of knowledge to every project.
                Our experts stay up-to-date with the latest trends,
                technologies, and best practices, allowing us to provide
                innovative solutions and valuable insights.
              </Typography>
            </div>
            <div className={css.item}>
              <img
                src="/images/home/personalization.png"
                alt="Expertise and leadership"
              />
              <Typography variant="body1" className={css.title}>
                Ownership & Responsibility
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={css.description}
              >
                We take full ownership of our projects and initiatives, ensuring
                that we deliver on our commitments and exceed expectations. With
                a strong sense of responsibility, we proactively address
                challenges, take accountability for our actions, and strive for
                continuous improvement.
              </Typography>
            </div>
            <div className={css.item}>
              <img src="/images/home/ux.png" alt="Expertise and leadership" />
              <Typography variant="body1" className={css.title}>
                Seamless User Experience
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={css.description}
              >
                We meticulously craft intuitive interfaces and smooth
                interactions to create an exceptional user journey. From the
                moment users engage with our products or services, we prioritize
                their needs and preferences, focusing on delivering an
                effortless and enjoyable experience.
              </Typography>
            </div>
            <div className={css.item}>
              <img
                src="/images/home/sustainability.png"
                alt="Expertise and leadership"
              />
              <Typography variant="body1" className={css.title}>
                Disruptive Innovation and Future-Proofing{" "}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={css.description}
              >
                We embrace cutting-edge technologies, stay ahead of industry
                trends, and constantly seek new ways to provide value to our
                clients. With a focus on innovation, we develop forward-thinking
                strategies and solutions that anticipate future challenges and
                opportunities.
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <section className={css.qualityAssurance + " container"}>
        <div>
          <Typography
            className={css.letterSpaced}
            variant="h6"
            sx={{ ml: "2rem" }}
          >
            WITH MOOHUB
          </Typography>
          <Typography
            className={css.letterSpaced}
            variant="h6"
            sx={{ color: "#000080", fontWeight: "bold", ml: "2rem" }}
          >
            THERE IS ZERO COMPROMISE ON QUALITY
          </Typography>
          <br />
          <Typography variant="subtitle2" sx={{ ml: "2rem" }}>
            At Moohub Technologies, we are committed to delivering excellence
            without compromise. Quality is at the core of everything we do, and
            we strive to exceed the expectations of our clients every step of
            the way. From the initial concept to the final product, our
            dedicated team ensures meticulous attention to detail, rigorous
            quality control measures, and the use of top-notch materials.
            <br />
            <br />
            We believe that quality is the cornerstone of success, and we go
            above and beyond to provide our customers with superior products and
            services that stand the test of time.
          </Typography>
        </div>
        <img src="/images/home/young-team.png" alt="Moohub Team" />
      </section>
    </>
  );
};

export default SetApart;
