import { Route, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

// Pages
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import Products from "./pages/Products";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Opening from "./pages/Opening";
import Snapp from "./pages/snapp/Snapp";
import SnappPrivacyPolicy from "./pages/snapp/SnappPrivacyPolicy";
import SnappTerms from "./pages/snapp/SnappTerms";

const App = () => {
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100%",
        }}
      >
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/snapp" element={<Snapp />} />
        <Route
          path="/products/snapp/privacy-policy"
          element={<SnappPrivacyPolicy />}
        />
        <Route path="/products/snapp/terms" element={<SnappTerms />} />

        <Route path="/careers" element={<Careers />} />

        <Route path="/contact" element={<Contact />} />

        <Route path="/openings/:id" element={<Opening />} />
      </Routes>
      <Footer />
      <ScrollToTop smooth color="#6f00ff" />
    </div>
  );
};

export default App;
