import css from "./team.module.css";

import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Team = () => {
  return (
    <div className={css.container}>
      <Typography
        fontWeight="bold"
        variant="h4"
        sx={{ fontSize: { xs: "1rem", sm: "1.7rem", md: "2rem" } }}
      >
        Meet the Team
      </Typography>

      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Over the last few months, we have grown from a two-man team into a
        competitive startup with 10 full-time staff with additional
        contract/resource personnels of varying ethnic/religious backgrounds and
        skill sets, ranging from design, administration, development to
        branding, marketing etc. A quick glance at some amiable members of our
        workforce . . .
      </Typography>

      <div className={css.teamSection}>
        {/* CEO */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/michael.jpeg" alt="Oluwole Michael" />
              <div className={css.designation}>
                <Typography variant="body2">Michael</Typography>
                <Typography variant="caption">
                  CEO
                </Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              Chief Executive Officer
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              I’m a customer driven and a result oriented Manager with more than 9 years experience planning and delivering multimillion dollars & complex projects and programs across multiple industries(data centers & semi-conductor industries). 
              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/michael-oluwole-684412123/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
          {/* CTO */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/Olatunji.jpeg" alt="Olatunji" />
              <div className={css.designation}>
                <Typography variant="body2">Olatunji</Typography>
                <Typography variant="caption">
                  CTO 
                </Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              Chief Technology Officer
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              Cloud Support Engineer (Developer Mobile, Messaging & IoT) at Amazon Connect (Cloud Contact Center)
              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/john-oluwole-a14832153/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
        {/* COO */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/mr-john2.jpeg" alt="Oluwole John" />
              <div className={css.designation}>
                <Typography variant="body2">John</Typography>
                <Typography variant="caption">
                  Chief Coordinating Officer
                </Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
                I am responsible for ensuring smooth running of affairs in the
                company. You need anything? I'm your goto guy
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
                When I'm not busy, I like to play video games, make music, &
                enjoy quality time with family & friends
              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/john-oluwole-a14832153/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>

        {/* ANIS */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/anis1.png" alt="Anietie" />
              <div className={css.designation}>
                <Typography variant="body2">Anietie</Typography>
                <Typography variant="caption">Business Development Manager</Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              I create Development plans and growth projections , identifying market opportunities through different Mediums and channels. Also meeting existing and potential clients while building positive relationships
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              Outside the Business Space, I am the Adventure guy. I play the piano, I Read alot, research about random things and I love to explore.
              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/anietieeffiong/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>

        {/* Kemisola */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/finance.png" alt="Avatar" />
              <div className={css.designation}>
                <Typography variant="body2">Kemisola</Typography>
                <Typography variant="caption">Finance Manager</Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              I am responsible for financial planning, budgeting and accounting, I manage all that has to do with the company's finance.
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              Outside of work I enjoy cooking, watching movies and I also love spending time with my family!


              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/john-oluwole-a14832153/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>

        {/* Solomon */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/solomon.jpeg" alt="Avatar" />
              <div className={css.designation}>
                <Typography variant="body2">Solomon</Typography>
                <Typography variant="caption">Fullstack Software Engineer</Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              I manage and maintain the company's web and mobile applications, I love what I do.
              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              I love keeping up with new trends in tech and I am an ardent lover of classical music.
              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/solomon-ojigbo/"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
        {/* Joshua */}
        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/joshua.jpeg" alt="Avatar" />
              <div className={css.designation}>
                <Typography variant="body2">Joshua</Typography>
                <Typography variant="caption">UI/UX & Graphics</Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
              I like to handle project ideation and design prototype & implementation of the latest tech


              </Typography>
              <br />
              <Typography>About Me</Typography>
              <Typography>
              I am a creative and ambitious Designer with a knack for outside the box thinking, innovative ideas and designs


              </Typography>
              <br />
              <a
                href="https://www.linkedin.com/in/akinboyewa-joshua-jursh-abb39b188"
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="linkedin" color="primary">
                  <LinkedInIcon
                    className={css.icon}
                    sx={{ fontSize: "2rem" }}
                  />
                </IconButton>
              </a>
            </div>
          </div>
        </div>


        {/* Anonymous */}

        <div className={css.flipCard}>
          <div className={css.flipCardInner}>
            <div className={css.flipCardFront}>
              <img src="/images/about/anonymous.png" alt="Anonymous" />
              <div className={css.designation}>
                <Typography variant="body2">You</Typography>
                <Typography variant="caption">???</Typography>
              </div>
            </div>
            <div className={css.flipCardBack}>
              <Typography>Role</Typography>
              <Typography>
                You love to work with technology, as an engineer, or maybe a
                designer, businessperson, etc.
              </Typography>
              <br />
              <Typography>About You</Typography>
              <Typography>
                The thrill of taking on challenging projects excites you, and
                you belive you're a good fit for a role at Moohub?
              </Typography>
              <br />
              <a href="/careers" target="_blank" rel="noreferrer">
                <Button variant="contained">Join Us</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
