import { Typography } from "@mui/material";
import css from "../../css/snapp.module.css";
import Hero from "../../components/hero/Hero";

const Snapp = () => {
  return (
    <div>
      <Hero
        title="Safe Naija App"
        description="Receive real-time notifications on incidents!
        Know where your loved ones are, verify their safety...always!
        Reach emergency services."
        exploreBtn="Download Free"
        bgImg="/products/safe-city.png"
        link="https://play.google.com/store/apps/details?id=com.moohub.safenaija"
      />
      <section className={css.heading}></section>
      <section className={css.body}>
        <div className={css.leftContent}>
          <Typography variant="h2" fontWeight="bold">
            Stay <span>safe</span>
          </Typography>
          <Typography variant="h2" fontWeight="bold">
            through connection
          </Typography>
          <br />
          <Typography variant="subtitle2">
            Verify the safety of your location before getting there
          </Typography>
          <br />
          <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
            <div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.moohub.safenaija"
          >
            <img
              src="/images/products/Google Play.png"
              className={css.playstoreBtn}
              alt="Google Play Link"
            />
          </a>
            </div>
            <div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://apps.apple.com/ng/app/safe-naija-app/id6447293333"
          >
            <img
              src="/images/products/apple.webp"
              className={css.playstoreBtn}
              alt="App Store Link"
            />
          </a>
            </div>
          </div>
          <div className={css.btnSection} style={{display: "flex", justifyContent: "center"}}>
            <a
              rel="noreferrer"
              target="_blank"
              href="/products/snapp/privacy-policy"
              className={css.btn}
            >
              Privacy Policy
            </a>
            <a href="/products/snapp/terms" rel="noreferrer" target="_blank">
              Terms of Use
            </a>
            <a href="https://safenaija.moohub.tech/" rel="noreferrer" target="_blank">
              Visit Website
            </a>
          </div>
        </div>
        <div>
          <img
            src="/images/products/iPhone.png"
            alt="iPhone"
            className={css.iPhone}
          />
        </div>
      </section>
    </div>
  );
};

export default Snapp;
