import css from "./our-clients.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "../ui/Card2/Card";
import Marquee from "react-marquee-slider";

const OurClients = () => {
  // const clients = [
  //   { img: "/images/home/clients/cutedge.png", desc: "#1 Record Label, Lagos" },
  //   { img: "/images/home/clients/cutedge.png", desc: "#1 Record Label, Lagos" },
  //   { img: "/images/home/clients/cutedge.png", desc: "#1 Record Label, Lagos" },
  //   { img: "/images/home/clients/cutedge.png", desc: "#1 Record Label, Lagos" },
  // ];

  return (
    <section className={"container"}>
      <div>
        <Typography
          fontWeight="bold"
          className={`${css.centerText} ${css.gradientText}`}
          variant="h4"
          display="block"
        >
          Our Clients
        </Typography>
        <br />
      </div>

      <div className={css.flex}>
        <Marquee velocity={50}>
          <Card>
            <Box className={css.content}>
              <img
                className={css.img}
                src="/images/home/clients/cutedge.png"
                alt="cutedge"
              />
              <br />
              <Typography variant="subtitle1">Media Production</Typography>
            </Box>
          </Card>

          <Card>
            <Box className={css.content}>
              <img
                className={css.img}
                src="/images/home/clients/clutch-inc.png"
                alt="clutch incorporated"
              />
              <br />
              <Typography variant="subtitle1">Immigration Services</Typography>
            </Box>
          </Card>
        </Marquee>
      </div>
    </section>
  );
};

export default OurClients;
