import { Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import css from "./footer.module.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/material";

const Footer = () => {
  const explore = [
    { label: "About Us", link: "/about" },
    { label: "Privacy Policy", link: "#" },
    { label: "Terms of Service", link: "#" },
    { label: "Join Moohub", link: "/careers" },
  ];
  const services = [
    "Web Development",
    "Games",
    "Metaverse Products",
    "Mobile Developement",
  ];

  const workingDays = ["Monday - Friday", "9AM - 4PM (GMT+1)", "FAQs"];

  return (
    <div className={css.footerContainer}>
      <footer className={css.footer}>
        <Box sx={{ width: { xs: "100%", sm: "100%", md: "25%" } }}>
          <a href="/">
            <img
              src="/images/home/white-footer-logo.png"
              width="200rem"
              alt="Moohub Logo"
            />
          </a>
          <Typography variant="body2" sx={{ mt: "1rem" }}>
            We provide the most valuable technology solution to real life
            problems of every diversity with a focus on usabilty and
            accessibilty.
          </Typography>
        </Box>
        <div>
          <Typography fontWeight="bold" className={css.linkTitle}>
            Services
          </Typography>
          {services.map((item) => (
            <Typography key={item} variant="body2">
              <a href="/#services">{item}</a>
            </Typography>
          ))}
        </div>
        <div>
          <Typography fontWeight="bold" className={css.linkTitle}>
            Explore
          </Typography>
          {explore.map((link) => (
            <Typography key={link.label} variant="body2">
              <a href={link.link}>{link.label}</a>
            </Typography>
          ))}
        </div>
        <div>
          <Typography fontWeight="bold" className={css.linkTitle}>
            Working Days (Onsite)
          </Typography>
          {workingDays.map((link) => (
            <Typography variant="body2" key={link}>
              {link}
            </Typography>
          ))}
        </div>
        <div>
          <Typography fontWeight="bold" className={css.linkTitle}>
            Contact Us
          </Typography>
          <Typography variant="body2">
            <a href="tel:+2348083654398">
            +1 (503) 984-7493, +1 (240) 906-1470
            </a>
          </Typography>
          <Typography variant="body2">
          US East Hub: 8112 Leland Road Manassas Virginia 20111
          </Typography>
          <Typography variant="body2">
          USA West Hub: 197th Place Beaverton, Oregon, USA
          </Typography>
          <div className={css.footerSocials}>
            <a href="mailto:john@moohub.com" target="_blank" rel="noreferrer">
              <EmailIcon />
            </a>
            <a
              href="https://web.facebook.com/profile.php?id=100088235710744"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/moo-hub/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://www.instagram.com/officialmoohub/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </div>
        </div>
      </footer>
      <Typography variant="caption" className={css.copyright}>
        Copyright &copy; {new Date().getFullYear()} Moohub technologies. All
        Rights Reserved
      </Typography>
    </div>
  );
};

export default Footer;
