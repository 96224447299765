import React from "react";
import css from "../css/contact.module.css";
import { Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const Contact = () => {
  return (
    <div>
      <div className={css.background}>
        <div className="container">
          <div className={css.contactContent}>
            <div className={css.contact}>
              <Typography
                variant="p"
                component="p"
                fontWeight="bold"
                sx={{ fontSize: { xs: "12px", sm: "12px", md: "14px" } }}
              >
                Get in touch with us
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "20px", sm: "20px", md: "24px" } }}
              >
                Have a project in mind? <br /> We’d love to hear it
              </Typography>
              <div className={css.location}>
                <div className={css.locationItem}>
                  <LocalPhoneIcon className={css.itemIcon} />
                  <a className={css.phoneLink} href="tel:+2348083654398">
                    +234 808 365 4398, +234 803 875 2985
                  </a>
                </div>
                <div className={css.locationItem}>
                  <LocalPostOfficeIcon className={css.itemIcon} />
                  <Typography
                    variant="p"
                    component="p"
                    fontWeight="bold"
                    sx={{ fontSize: { xs: "12px", sm: "12px", md: "13px" } }}
                  >
                    support@moohub.tech
                  </Typography>
                </div>
                <div className={css.locationItem}>
                  <LocationOnIcon className={css.itemIcon} />
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ fontSize: { xs: "12px", sm: "12px", md: "13px" } }}
                  >
                    Bamboo plaza Annex, Ifo local Government, BPX 201, Noble Cl,
                    Berger Lagos
                  </Typography>
                </div>
              </div>
            </div>
            <div className={css.contactForm}>
              <Typography
                variant="p"
                component="p"
                fontWeight="bold"
                sx={{ fontSize: { xs: "13px", sm: "15px", md: "16px" } }}
              >
                Leave us a message
              </Typography>
              <form>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Full Name</label> <br />
                  <input
                    type="text"
                    id="my-input"
                    placeholder="Joy John"
                    aria-describedby="my-helper-text"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Email address</label> <br />
                  <input
                    type="email"
                    id="my-input"
                    placeholder="name@domain"
                    aria-describedby="my-helper-text"
                  />
                </div>
                <div className={css.formInput}>
                  <label htmlFor="my-input">Message</label> <br />
                  <textarea
                    aria-label="empty textarea"
                    placeholder="Write message here"
                  />
                </div>
                <div>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <div className={css.bookMeeting}>
            <p>Prefer to book a meeting instead?</p>
            <a
              href="https://calendly.com/moohub-technologies"
              target="_blank"
              rel="noreferrer"
            >
              Book A Meeting
            </a>
          </div>
        </div>
      </div>

      <section className={css.mapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.081572248591!2d3.352208014006525!3d6.6367916952009125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9b40517b1129%3A0x7d38593df5727cb5!2sMoohub%20Technologies!5e0!3m2!1sen!2sng!4v1676377230547!5m2!1sen!2sng"
          width="100%"
          height="450"
          title="map"
          style={{ border: 0 }}
          // allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </div>
  );
};

export default Contact;
