import css from "./dev-process.module.css";
import { Typography } from "@mui/material";

const DevelopmentProcess = () => {
  return (
    <div className={css.container}>
      <div>
        <Typography variant="h6" fontWeight="bold" className="gradient-text">
          Our Development Process
        </Typography>
      </div>
      <div className={css.processImage}>
        <img
          loading="lazy"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          src="/images/about/research.png"
          alt="Research"
          className="img"
        />
        <img
          loading="lazy"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="600"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          src="/images/about/design.png"
          alt="Design"
          className="img"
        />
        <img
          loading="lazy"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="800"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          src="/images/about/implementation.png"
          alt="Implementation"
          className="img"
        />
        <img
          loading="lazy"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="1000"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          src="/images/about/testing.png"
          alt="Testing"
          className="img"
        />
        <img
          loading="lazy"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="1100"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          src="/images/about/maintenance.png"
          alt="Maintenance"
          className="img"
        />
      </div>
    </div>
  );
};

export default DevelopmentProcess;
