import css from "./our-services.module.css";
import { Typography } from "@mui/material";
import { AttentionSeeker } from "react-awesome-reveal";

const OurServices = () => {
  return (
    <div className={"container " + css.services}>
      <div className={css.flexCenter}>
        <Typography
          fontWeight="bold"
          className={`${css.centerText} ${css.gradientText}`}
          variant="h4"
        >
          Our Services
        </Typography>
        <br />
      </div>
      <div className={css.flex}>
        <Typography
          variant="subtitle2"
          sx={{ lineHeight: { xs: "1.2rem", sm: "1.5rem", md: "2rem" } }}
          className={css.serviceDescription}
        >
          A lot of technological organizations struggle to maximize efficiency
          while retaining their fast evolving customers, but we live to provide
          accessible, and customer-driven solutions, resulting in great
          products.
        </Typography>

        <div className={css.serviceImages}>
          <AttentionSeeker effect="headShake">
            <img src="/images/home/games.png" alt="Games" />
          </AttentionSeeker>
          <AttentionSeeker effect="swing">
            <img src="/images/home/mobile.png" alt="Mobile Development" />
          </AttentionSeeker>
          <AttentionSeeker effect="headShake">
            <img
              src="/images/home/software-development.png"
              alt="Software Development"
            />
          </AttentionSeeker>
          <AttentionSeeker effect="swing">
            <img src="/images/home/metaverse1.png" alt="Metaverse" />
          </AttentionSeeker>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
