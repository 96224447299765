import { Fade, Zoom } from "react-awesome-reveal";

import css from "../css/about.module.css";
import { Typography } from "@mui/material";
import Card from "../components/ui/Card/Card";
import Team from "../components/team-section/Team";

const About = () => {
  return (
    <div>
      <div>
        <section className={css.hero}>
          <div className={css.videoOverlay}></div>
          <video
            src="/images/about/about-video.mp4"
            controls={false}
            muted={true}
            autoPlay={true}
            loop={true}
            className={css.video}
          >
            Your browser does not support the video tag
          </video>
        </section>
        <section>
          <div className="container">
            <br />

            <Fade cascade damping={0.6}>
              <Typography
                fontWeight="bold"
                className={`${css.centerText} ${css.gradientText}`}
                variant="h4"
              >
                Our Story
              </Typography>
              <br />

              <div className={css.smallWidth}>
                <Typography
                  className={css.centerText}
                  variant="body2"
                  sx={{ lineHeight: "2rem" }}
                >
                  Moohub Technologies was birthed in 2022 on the desire to
                  employ new ways of solving problems for individuals,
                  businesses, and the society. While there are many
                  technological startups today, many of these fail to utilize
                  the trending standards today, which includes using the most
                  modern and best software tools for each task, the best staff
                  available, and providing the most optimal solutions to user
                  needs. We are both product- and service-based.
                  <br />
                  Find below our core values and the team that makes things
                  happen!
                </Typography>
              </div>
            </Fade>
          </div>
          <br />
        </section>

        <section className={css.coreValueSection}>
          <div className="container">
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                fontSize: { xs: "1rem", sm: "1.3rem", md: "2.1rem" },
                textAlign: "center",
              }}
            >
              Our Core Values
            </Typography>
            <div className={css.coreValues}>
              <Zoom>
                <Card>
                  <div className={css.valueHeaderContent}>
                    <img
                      loading="lazy"
                      src="/images/about/commitment.png"
                      className={css.img}
                      alt="Security Icon"
                    />
                    <Typography variant="body1" fontWeight="bold">
                      Customer-Centricity
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    At Moohub Technologies, our clients' satisfaction comes
                    first. Needs fuel our passion and we deliver with an aim to
                    please.
                  </Typography>
                </Card>
              </Zoom>

              <Zoom>
                <Card>
                  <div className={css.valueHeaderContent}>
                    <div className={css.valueIcon}>
                      <img
                        loading="lazy"
                        src="/images/about/value.png"
                        className={css.img}
                        alt="Value Icon"
                      />
                    </div>
                    <Typography variant="body1" fontWeight="bold">
                      Value-Centricity
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Value is a currency that cannot be overemphasized. Our
                    services are laced with this very ingredient that is bound
                    to guarantee longevity.
                  </Typography>
                </Card>
              </Zoom>
              <Zoom>
                <Card>
                  <div className={css.valueHeaderContent}>
                    <div className={css.valueIcon}>
                      <img
                        loading="lazy"
                        src="/images/about/security.webp"
                        className={css.img}
                        alt="Security Icon"
                      />
                    </div>
                    <Typography variant="body1" fontWeight="bold">
                      Security
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    Our clients' work is treasure to us. With whatever we do,
                    you can sit back and be rest assured of optimum security and
                    privacy
                  </Typography>
                </Card>
              </Zoom>
              <Zoom>
                <Card>
                  <div className={css.valueHeaderContent}>
                    <div className={css.valueIcon}>
                      <img
                        loading="lazy"
                        src="/images/about/capacity.png"
                        className={css.img}
                        alt="Capacity Icon"
                      />
                    </div>
                    <Typography variant="body1" fontWeight="bold">
                      Capacity
                    </Typography>
                  </div>
                  <Typography variant="body2">
                    You think it! We build it! Everything from branding to
                    creating an online presence for your businesses, building
                    soft solutions, you name it!
                  </Typography>
                </Card>
              </Zoom>
            </div>
          </div>
        </section>
      </div>
      <div className="container">
        <Team />
      </div>
    </div>
  );
};

export default About;
