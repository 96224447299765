import { Button, IconButton, Typography, Box } from "@mui/material";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import css from "./hero.module.css";

const Hero = ({
  title,
  description,
  exploreBtn,
  learnBtn,
  bgImg,
  chatBtn,
  link,
}) => {
  const bgFill = `linear-gradient(0deg, rgba(9, 9, 48, 0.2), rgba(9, 9, 48, 0.2)),
radial-gradient(
  61.68% 61.68% at 47.05% 41.69%,
  rgba(230, 231, 248, 0.2) 0%,
  rgba(255, 255, 255, 0.2) 14.8%,
  rgba(25, 0, 106, 0.2) 100%
),
linear-gradient(
  121.25deg,
  rgba(159, 25, 81, 0.4) 32.27%,
  rgba(0, 0, 128, 0.4) 61.89%
),
url("/images/${bgImg}");`;

  return (
    <Box
      className={css.heroSection}
      sx={{
        background: bgFill,
      }}
    >
      <div className="container">
        <div className={css.content}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="subtitle2">{description}</Typography>

          <div>
            {exploreBtn && (
              <a
                href={link ? link : "#careers"}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
                target={
                  link ===
                  "https://play.google.com/store/apps/details?id=com.moohub.safenaija"
                    ? "_blank"
                    : "_self"
                }
              >
                <Button variant="contained">{exploreBtn}</Button>
              </a>
            )}
            {learnBtn && <Button variant="contained">{learnBtn}</Button>}
          </div>
        </div>

        {chatBtn && (
          <IconButton className={css.chatBtn} color="primary" component="label">
            <MarkUnreadChatAltIcon />
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default Hero;
