import { Button, Card, Typography } from "@mui/material";
import Hero from "../components/hero/Hero";
import css from "../css/products.module.css";
import Card2 from "../components/ui/Card2/Card";
import React from 'react';
import YouTube from 'react-youtube';


const Products = () => {

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  }

  const opts = {
    height: 300,
    width: 295,
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className={css.productContainer}>
      <Hero
        title="Our Products"
        description="With use of the latest software technologies, we are creating the most viable prodcuts out there.
        Our products include Web Apps, Mobile Apps, and more... "
        exploreBtn="View Products"
        bgImg="/products/hero-bg.png"
        link="#our-products"
      />

      <section className={"container " + css.productsSection} id="our-products">
        <Typography sx={{ mb: 3 }} variant="h6" className={css.centerText}>
          Safe Naija App for iOS & Android. Available Now!!!
        </Typography>
        <div className={css.cardContainer}>
        <Card2 className={css.card}>
        <div className={css.product}>
            <YouTube videoId="PwvURLXrqZk" opts={opts} onReady={onPlayerReady} />
          </div>
          <div>
          <a href="/products/snapp" className={css.productLink}>
          <Typography variant="caption">
              Learn More About Safe Naija.<br/>
              
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              Join the Fight Against Insecurity.
            </Typography>
          </a>
          </div>
        </Card2>
        <Card2>
            <div className={css.product}>
          <a href="https://safenaija.moohub.tech" className={css.productLink}>
              <img
                
                src="/images/products/snapp-logo.png"
                alt="Safe Naija App"
                className={css.productLogo}
                height={300}
              />
          </a>
            </div>
            <div style={{paddingTop: 15}}>

            <Typography variant="body2" fontWeight="bold">
            Safe Naija App
            </Typography>
            <Typography variant="caption">
            Download the apps on Google Play and App Store.
            </Typography>
            </div>
        

          <div className={css.btnSection} style={{display: "flex", justifyContent: "center", marginTop: "12px", gap: 10}}>
            <a
              rel="noreferrer"
              target="_blank"
              href="/products/snapp"
              className={css.btn}
              >
               <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  borderRadius: "23px",
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    radial-gradient(67.01% 171.56% at 69.9% 57.72%, #3f39a0 0%, #121439 100%);`,
                }}
                variant="contained"
              >
               Download App
              </Button>
            </a>
            <a href="https://safenaija.moohub.tech/" rel="noreferrer" target="_blank">

               <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  borderRadius: "23px",
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    radial-gradient(67.01% 171.56% at 69.9% 57.72%, #3f39a0 0%, #121439 100%);`,
                }}
                variant="contained"
              >
                Visit Product Website
              </Button>
            </a>
          </div>
            
        </Card2>
        </div>
      </section>
    </div>
  );
};

export default Products;
