import css from "./loader.module.css";

import React from "react";

const Loader = () => {
  return (
    <div className={css.container}>
      <img
        className={css.logo}
        src="/images/home/mlogo.png"
        alt="Moohub Logo"
        width="100rem"
        height="100rem"
      />
    </div>
  );
};

export default Loader;
