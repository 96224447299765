import Button from "@mui/material/Button";
import Hero from "../components/hero/Hero";
import css from "../css/careers.module.css";
import Card from "../components/ui/Card2/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Careers = () => {
  return (
    <div className={css.careersHero}>
      <Hero
        title="Think You've Got What It Takes To Be A Part Of MooHub?"
        description="Join our fast growing team today and be a part of the leading
        and number one technology solution and metaverse
        company in Nigeria."
        exploreBtn="Join Moohub"
        bgImg="/careers/career-bg.svg"
      />
      <Typography variant="h5" className={css.careersHeader}>
        Career Opportunities at Moohub
      </Typography>
      <section className={`container ${css.container}`}>
        <div className={css.openings}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <Typography fontWeight="bold" className={css.openingTitle}>
                  Frontend Developer
                </Typography>
                <Typography component="p">Job Type: Onsite</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "grey", textAlign: "left" }}
              >
                <Typography fontWeight="bold">About the Role</Typography>
                Responsible for the design, documentation, development, testing
                and deployment of frontend applications (mobile and web), with
                core understanding of software design principles and deployment
                strategies to the Playstore and App store.
                <br />
                <br />
                <Typography fontWeight="bold">About You</Typography>
                <Typography>You satisfy the following requirements:</Typography>
                <ul>
                  <li>
                    Bachelors or Master's Degree in an Information Technology or
                    related discipline.
                  </li>
                  <li>
                    2+ strong years of experience in frontend application
                    development using React is a must
                  </li>
                  <li>
                    Experience with production applications and deployments to
                    app stores is a must
                  </li>
                  <li>
                    Experience with using styling libraries such as Material UI,
                    Tailwind, Bootstrap
                  </li>
                  <li>
                    Strong communication, presentation and interpersonal skills.
                  </li>
                  <li>
                    Ability to quickly learn systems, processes, and procedures
                    on demand
                  </li>
                  <li>
                    Strong understanding of REST APIs and Git/GitHub is a must
                  </li>
                </ul>
              </Typography>
              <div className={css.row}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/xff2WzWHMae76TAA6"
                >
                  <Button
                    // disabled
                    className={css.applyBtn}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <Typography fontWeight="bold" className={css.openingTitle}>
                  UI/UX Designer
                </Typography>
                <Typography component="p">Job Type: Onsite</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "grey", textAlign: "left" }}
              >
                <Typography fontWeight="bold">About the Role</Typography>
                Responsible for collaborating with cross-functional teams to
                understand project requirements and translate them into
                captivating and user-friendly designs. Conducting user research
                and gather insights to inform the design process. Creating
                wireframes, prototypes, and high-fidelity mockups to illustrate
                design concepts and interactions. Designing and refining user
                flows, information architecture, and navigation structures to
                enhance the user experience. Develop visual design elements,
                including icons, color schemes, and typography, that align with
                the brand identity and usability standards.
                <br />
                <br />
                <Typography fontWeight="bold">About You</Typography>
                <Typography>You satisfy the following requirements:</Typography>
                <ul>
                  <li>
                    Proficiency in design and prototyping tools such as Figma,
                    Sketch, Adobe XD, or similar. Solid understanding of
                    user-centered design principles, interaction design, and
                    information architecture.
                  </li>
                  <li>
                    Experience in conducting user research, usability testing,
                    and incorporating feedback into design iterations.
                  </li>
                  <li>
                    Strong visual design skills, including layout, color theory,
                    typography, and iconography. Excellent attention to detail
                    and ability to create pixel-perfect designs.
                  </li>
                  <li>
                    Strong communication and collaboration skills, with the
                    ability to effectively articulate design concepts and
                    rationale to stakeholders.
                  </li>
                  <li>
                    Ability to quickly learn systems, processes, and procedures
                    on demand
                  </li>
                </ul>
              </Typography>
              <div className={css.row}>
                <Typography sx={{ color: "lightblue" }}>
                  Job Type: Onsite
                </Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/xff2WzWHMae76TAA6"
                >
                  <Button
                    // disabled
                    className={css.applyBtn}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <Typography fontWeight="bold" className={css.openingTitle}>
                  Mobile Developer
                </Typography>
                <Typography component="p">Job Type: Onsite</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "grey", textAlign: "left" }}
              >
                <Typography fontWeight="bold">About the Role</Typography>
                As a Mobile Developer, you will be responsible for designing,
                developing, and maintaining the front-end of our mobile
                applications using React Native, and/or Flutter, with experience
                of React Native Expo. You will work closely with our
                cross-functional team, including designers, back-end developers,
                and product managers, to deliver high-quality, visually
                appealing mobile applications. The ideal candidate is passionate
                about creating exceptional user experiences and possesses a
                strong command of modern mobile development technologies.
                <br />
                <br />
                <Typography fontWeight="bold">About You</Typography>
                <Typography>You satisfy the following requirements:</Typography>
                <ul>
                  <li>Expertise on React Native (Expo) is a must.</li>
                  <li>
                    Proven experience with production applications deployed to
                    app stores (ioS and Android)
                  </li>
                  <li>
                    Ability to develop mobile apps that match UI designs
                    pixel-perfectly
                  </li>
                  <li>
                    Bachelor's degree in Computer Science, Software Engineering,
                    or a related field (or equivalent experience).
                  </li>
                  <li>
                    Strong understanding of REST APIs and Git/GitHub is a must
                  </li>
                  <li>Experience with TypeScript and React is a plus</li>
                </ul>
              </Typography>
              <div className={css.row}>
                <Typography sx={{ color: "lightblue" }}>
                  Job Type: Onsite
                </Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/xff2WzWHMae76TAA6"
                >
                  <Button
                    // disabled
                    className={css.applyBtn}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <Typography fontWeight="bold" className={css.openingTitle}>
                  Accountant
                </Typography>
                <Typography component="p">Job Type: Onsite</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "grey", textAlign: "left" }}
              >
                <Typography fontWeight="bold">About the Role</Typography>
                As an Accountant at Moohub Technologies, you will be responsible
                for managing the financial operations and ensuring compliance
                with accounting principles and regulations. You will play a key
                role in maintaining accurate financial records, preparing
                financial statements, and contributing to the overall financial
                health of the company. This position requires meticulous
                attention to detail, strong organizational skills, and a solid
                understanding of accounting principles and best practices.
                <br />
                <br />
                <Typography fontWeight="bold">About You</Typography>
                <Typography>You satisfy the following requirements:</Typography>
                <ul>
                  <li>
                    Bachelor's degree in Accounting, Finance, or a related
                    field. CPA certification is preferred. 2 years of relevant
                    experience in accounting, preferably in a startup (tech
                    company) or fast-paced environment.
                  </li>
                  <li>
                    Strong knowledge of accounting principles, financial
                    statements, and financial analysis. Proficiency in using
                    accounting software and tools (e.g., QuickBooks, Excel,
                    etc.).
                  </li>
                  <li>
                    Excellent attention to detail, organizational skills, and
                    ability to work with numerical data accurately.
                  </li>
                  <li>
                    Effective communication skills to collaborate with
                    cross-functional teams and present financial information
                    clearly.
                  </li>
                  <li>
                    Ability to work independently, meet deadlines, and
                    prioritize tasks in a dynamic startup environment.
                  </li>
                  <li>
                    Strong analytical and problem-solving skills, with the
                    ability to identify and resolve accounting issues.
                  </li>
                </ul>
              </Typography>
              <div className={css.row}>
                <Typography sx={{ color: "lightblue" }}>
                  Job Type: Onsite
                </Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/xff2WzWHMae76TAA6"
                >
                  <Button
                    // disabled
                    className={css.applyBtn}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <Typography fontWeight="bold" className={css.openingTitle}>
                  Graphics Designer
                </Typography>
                <Typography component="p">Job Type: Onsite</Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{ color: "grey", textAlign: "left" }}
              >
                <Typography fontWeight="bold">About the Role</Typography>
                As a Graphic Designer at Moohub Technologies, you will play a
                crucial role in shaping our visual brand identity and delivering
                captivating designs for various digital and print mediums.
                Collaborating with our marketing, product, and development
                teams, you will create compelling visual assets that communicate
                our brand values, engage our target audience, and enhance user
                experiences. This is an excellent opportunity for a creative and
                detail-oriented individual to contribute to the growth and
                success of a fast-paced technology company.
                <br />
                <br />
                <Typography fontWeight="bold">About You</Typography>
                <Typography>You satisfy the following requirements:</Typography>
                <ul>
                  <li>
                    Proficiency in industry-standard design software, such as
                    Adobe Creative Suite (Photoshop, Illustrator, InDesign),
                    Sketch, or Figma.
                  </li>
                  <li>
                    Conceptualize, design, and execute visually appealing and
                    impactful designs for digital platforms, including websites,
                    mobile apps, social media, and digital marketing campaigns.
                    Develop visually stunning graphics, infographics, icons, and
                    illustrations to enhance user interfaces and user
                    experiences.
                  </li>
                  <li>
                    Strong understanding of design principles, typography, color
                    theory, and layout. Familiarity with responsive design
                    principles and designing for different devices and screen
                    sizes.
                  </li>
                  <li>
                    Work closely with the marketing team to support the creation
                    of marketing collateral, including banners, advertisements,
                    email templates, and landing pages.
                  </li>
                  <li>
                    Collaborate with developers to provide design assets and
                    ensure accurate implementation of designs.
                  </li>
                  <li>
                    Excellent communication skills with the ability to present
                    and articulate design concepts effectively.
                  </li>
                </ul>
              </Typography>
              <div className={css.row}>
                <Typography sx={{ color: "lightblue" }}>
                  Job Type: Onsite
                </Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://forms.gle/xff2WzWHMae76TAA6"
                >
                  <Button
                    // disabled
                    className={css.applyBtn}
                    variant="contained"
                  >
                    Apply
                  </Button>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </section>
    </div>
  );
};

export default Careers;
