import { Typography } from "@mui/material";

const SnappTerms = () => {
  return (
    <div className="container" style={{ marginTop: "5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4">Safe Naija App Terms of Use</Typography>
      </div>
      <div>
        <p>
          <h4>TERMS OF USE FOR THE SAFENAIJA APPLICATION </h4>
          Welcome to the Terms of Use for the SafeNaija application (the "App").
          The App is designed to provide you with security features that include
          incident reporting to local authorities, emergency and criminal event
          recording and sharing, and emergency contact storage. The following
          Terms of Use (the "Terms") govern your use of the App and constitute a
          legally binding agreement between you and the app's owner (the
          "Owner"). Please read these Terms carefully before using the App. By
          accessing or using the App, you agree to be bound by these Terms, as
          well as any additional guidelines or rules posted on the App. If you
          do not agree to these Terms, please do not use the App. The Owner
          reserves the right to modify these Terms at any time, without prior
          notice, and your continued use of the App following such modifications
          constitutes your acceptance of the modified Terms. It is your
          responsibility to regularly review these Terms to ensure that you are
          aware of any modifications. Your use of the App is also subject to the
          Owner's Privacy Policy, which describes how the Owner collects, uses,
          and protects your personal information. By using the App, you consent
          to the collection and use of your personal information as described in
          the Privacy Policy. If you have any questions or concerns about these
          Terms or the App, please contact the Owner at enquiries@safenaija.com
          or through www.moohub.tech/safenaija .
        </p>
        <p>
          <h4>USER APP CONDUCT</h4>
          By using the App, you agree to use it for lawful purposes and in
          compliance with all applicable laws and regulations. You shall not use
          the App to harm, threaten, or harass others, or to promote bigotry,
          racism, or hatred. You shall also not use the App to engage in
          fraudulent, deceptive, or unfair business practices. You shall not
          transmit any viruses, malware, or other harmful code through the App,
          or use the App to interfere with its security or functionality, or to
          interfere with the use of the App by others. You shall not gain
          unauthorized access to any data or information on the App, and shall
          not infringe upon the intellectual property rights of others through
          the use of the App. Furthermore, you shall not use the App to transmit
          any unsolicited or unauthorized advertising, promotional materials, or
          spam. You shall also not engage in any activity that violates these
          Terms of Use or the Privacy Policy. The Owner reserves the right to
          monitor the use of the App and to take any action it deems necessary
          to enforce these Terms, including the termination of your use of the
          App. The Owner may also report any activity that it suspects is in
          violation of any applicable laws or regulations. You acknowledge and
          agree that you are solely responsible for your conduct and any content
          you submit or transmit through the App. You also acknowledge and agree
          that the Owner has no obligation to monitor any user conduct or
          content, but may do so at its sole discretion. The Owner shall not be
          liable for any errors or omissions in any content, or for any loss or
          damage of any kind incurred as a result of the use of any content
          transmitted via the App.
        </p>
        <p>
          <h4>USER CONTENT</h4>
          The App allows you to upload, submit, store, send or receive content,
          such as text, images, video, audio, and other material. You are solely
          responsible for the content that you submit or transmit through the
          App, and you retain all ownership rights in your content. By
          submitting or transmitting content through the App, you grant the
          Owner a non-exclusive, royalty-free, worldwide, perpetual, and
          irrevocable license to use, reproduce, modify, adapt, publish,
          translate, create derivative works, distribute, publicly perform, and
          display your content in connection with the App and its promotion,
          including without limitation for promoting and redistributing part or
          all of the App (and derivative works thereof) in any media formats and
          through any media channels. You warrant that you have all the rights,
          licenses, permissions, and consents necessary to grant the above
          license to the Owner, and that your content does not infringe upon the
          intellectual property rights of any third party or violate any
          applicable laws or regulations. You shall not submit or transmit
          through the App any content that is defamatory, obscene, pornographic,
          offensive, or otherwise objectionable, or that promotes illegal
          activities, violence, or discrimination, except if after stringent
          review, is directly related to the report of a crime or emergency
          incident. The Owner does not claim originality of your content and
          shall not be responsible for its accuracy, completeness, legality, or
          reliability. The Owner has the right, but not the obligation, to
          monitor, edit, or remove any content that it deems to be in violation
          of these Terms of Use, or that it determines is otherwise
          objectionable. The Owner may also suspend or terminate your access to
          the App or take other actions it deems necessary to enforce these
          Terms. You acknowledge and agree that the Owner shall not be liable
          for any damages or losses of any kind arising from the use of any
          content submitted or transmitted through the App. You also acknowledge
          and agree that the Owner has no obligation to store or maintain any
          content that you submit or transmit through the App, and that the
          Owner may delete or remove any content at any time and for any reason.
          You are solely responsible for creating backup copies of any content
          that you submit or transmit through the App.
        </p>
        <p>
          <h4>DISPUTE RESOLUTION</h4>
          In the event of any dispute arising out of or relating to these Terms
          of Use, the parties shall first attempt to resolve the dispute through
          good faith negotiation. If the parties are unable to resolve the
          dispute through negotiation, either party may submit the dispute to
          binding arbitration in accordance with the Nigerian Arbitration and
          Conciliation Act. The arbitration shall be conducted by a single
          arbitrator appointed in accordance with the rules of the Nigerian
          Institute of Chartered Arbitrators. The place of arbitration shall be
          Lagos, Nigeria, and the language of the arbitration shall be English.
          The award rendered by the arbitrator shall be final and binding on the
          parties, and judgment may be entered on the award in any court of
          competent jurisdiction. The parties hereby waive any right to a trial
          by jury, and agree that any dispute resolution proceedings will be
          conducted only on an individual basis and not in a class, consolidated
          or representative action. Nothing in this section shall prevent either
          party from seeking injunctive or other equitable relief from the
          courts as necessary to prevent the actual or threatened infringement,
          misappropriation or violation of that party's copyrights, trademarks,
          trade secrets, patents or other intellectual property rights.
        </p>
        <p>
          <h4>USER ACCOUNTS</h4>
          To use certain features of the App, you may be required to create an
          account and provide certain personal information, including your name,
          email address, and phone number. You agree that all information you
          provide to the App in connection with your account will be accurate,
          current and complete, and you will keep your account information
          up-to-date. You are solely responsible for maintaining the
          confidentiality of your account password and for all activities that
          occur under your account. You agree to immediately notify the App of
          any unauthorized use, or suspected unauthorized use of your account or
          any other breach of security. The App shall not be liable for any loss
          or damage arising from your failure to comply with these requirements.
          You may not use anyone else's account at any time, and you may not
          transfer your account to another person. The App reserves the right to
          close your account at any time for any or no reason. The App also
          reserves the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in its sole discretion.
        </p>
        <p>
          <h4>PAYMENTS</h4>
          The App may provide certain features or services that require payment
          of fees. If you wish to use such features or services, you will be
          required to provide the App with a valid payment method, such as a
          debit card, and authorize the App to charge your payment method for
          the applicable fees. You represent and warrant that you have the legal
          right to use any payment method you provide to the App. The App
          reserves the right to refuse or cancel any transaction or order for
          any reason, including but not limited to unauthorized use of a payment
          method or non-payment. All fees are non-refundable, except as
          expressly set forth in these Terms of Use or required by applicable
          law. If you dispute any charges, you must notify the App in writing
          within three (3) days of the date of the charge, otherwise, you waive
          any right to dispute such charges. The App may modify the fees for its
          services at any time, in its sole discretion. Any fee changes will be
          effective immediately upon notice to you. Your continued use of the
          App after the fee change becomes effective constitutes your agreement
          to pay the modified fees. You agree to indemnify and hold the App
          harmless from and against any claims, losses, damages, or expenses
          arising out of or in connection with any unauthorized use of your
          payment method or the App's processing of any payment made by you.
        </p>

        <p>
          <h4>TERMINATION</h4>
          We reserve the right to terminate your access to the mobile emergency
          security app at any time and for any reason, including if we believe
          that you have violated these terms of use. Upon termination, all
          rights granted to you under these terms will immediately cease, and
          you must immediately cease all use of the mobile emergency security
          app. Any provisions of these terms that by their nature should survive
          termination shall survive termination, including but not limited to
          the Limitation of Liability and Applicable Law sections. You may also
          terminate these terms at any time by deleting the mobile emergency
          security app from your device and ceasing all use of the app.
        </p>
        <p>
          <h4>APPLICABLE LAW</h4>
          Your use of the mobile emergency security app and any disputes arising
          from it are subject to the laws and regulations of Nigeria. By using
          the app, you agree to submit to the exclusive jurisdiction of the
          Nigerian courts. The Nigerian Communications Commission (NCC)
          regulates all communications services in Nigeria, including mobile
          applications. As such, your use of the mobile emergency security app
          is subject to the rules and regulations of the NCC, as well as any
          other relevant Nigerian laws and regulations. Any disputes arising
          from your use of the mobile emergency security app will be resolved in
          accordance with Nigerian law and through the Nigerian legal system.
          You agree to waive any objections to the jurisdiction of Nigerian
          courts on the grounds of venue or inconvenience of forum. By using the
          mobile emergency security app, you agree to comply with all applicable
          Nigerian laws and regulations, including those related to data
          privacy, cybersecurity, and intellectual property rights. If any
          provision of these terms is found to be invalid or unenforceable by a
          court of competent jurisdiction, the invalidity or unenforceability of
          such provision shall not affect the other provisions of these terms,
          which shall remain in full force and effect. Please note that this is
          just a sample, and you may need to consult with a legal expert
          familiar with Nigerian law to ensure that your Applicable Law section
          is accurate and complete.
        </p>

        <p>
          <h4>LIMITATION OF LIABILITY</h4>
          Your use of the mobile emergency security app is at your own risk. The
          app is provided "as is" and without warranties of any kind, either
          express or implied. We do not warrant that the app will meet your
          requirements or that it will be uninterrupted or error-free. In no
          event shall we or our affiliates, officers, directors, employees,
          agents, or suppliers be liable for any indirect, incidental, special,
          or consequential damages arising out of or in connection with your use
          of the mobile emergency security app, including but not limited to
          damages for loss of profits, data, or use. To the extent permitted by
          applicable law, our total liability to you for any damages, losses,
          and causes of action shall not exceed the amount you paid, if any, to
          access the mobile emergency security app. We do not accept any
          liability for any loss or damage that is not foreseeable or outside of
          our reasonable control. This limitation of liability shall apply
          regardless of the form of action, whether in contract, tort (including
          negligence), strict liability, or otherwise, and whether or not we
          have been advised of the possibility of such damages.
        </p>

        <p>
          <h4>MODIFICATION OF TERMS</h4>
          We reserve the right to modify or amend these terms of use at any time
          without prior notice. Your continued use of the mobile emergency
          security app after any changes to these terms shall constitute your
          acceptance of such changes. We encourage you to review these terms of
          use periodically to stay informed of any updates or changes. If you do
          not agree to any modified terms, you must stop using the mobile
          emergency security app immediately. If any provision of these terms is
          found to be invalid or unenforceable by a court of competent
          jurisdiction, the invalidity or unenforceability of such provision
          shall not affect the other provisions of these terms, which shall
          remain in full force and effect.
        </p>
        <p>
          <h4>THIRD-PARTY LINKS</h4>
          The mobile emergency security app may contain links to third-party
          websites or resources that are not owned or controlled by us. We do
          not endorse or assume any responsibility for the content, privacy
          policies, or practices of any third-party websites or resources. We
          recommend that you review the terms of use and privacy policies of any
          third-party websites that you access through the mobile emergency
          security app. You acknowledge and agree that we shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with your use of or
          reliance on any content, goods, or services available on or through
          any such third-party websites or resources. You further acknowledge
          and agree that we shall not be liable for any unauthorized access to
          or alteration of your transmissions or data, any material or data sent
          or received or not sent or received, or any transactions entered into
          through any third-party website or service.
        </p>
      </div>
    </div>
  );
};

export default SnappTerms;
