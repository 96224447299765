import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";

import css from "./navbar.module.css";

const drawerWidth = 240;
const navItems = [
  { text: "Home", link: "/" },
  { text: "Services", link: "/#services" },
  { text: "About Us", link: "/about" },
  { text: "Our Products", link: "/products" },
  { text: "Careers", link: "/careers" },
  { text: "Contact Us", link: "/contact" },
];

const Navbar = (props) => {
  let location = useLocation();
  location = location.pathname.split("/");
  let path = "/" + location[location.length - 1];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", color: "#000000" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        Moohub Tech
      </Typography>
      <Divider />

      <List>
        {navItems.map((item, index) => (
          // <ListItem key={item.text} disablePadding>
          //   <ListItemButton sx={{ textAlign: "left", pl: 7 }}>
          //     <ListItemText primary={item.text} />
          //   </ListItemButton>
          // </ListItem>
          <a
            key={item.index + item.link}
            href={item.link}
            className={css.drawerLinks}
          >
            {item.text}
          </a>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", m: 0, p: 0 }}>
      <AppBar component="nav" sx={{ backgroundColor: "#FFFFFF" }}>
        <Toolbar
          className={css.toolbar}
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 0,
            pr: 1,
            // maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{
                color: "#000000",
                display: { xs: "block", sm: "block", md: "none" },
                width: "20%",
              }}
            >
              <MenuIcon />
            </IconButton>
            <a href="/" className={css.logo}>
              <img
                src="/images/home/logo4016.png"
                width="190em"
                height="50em"
                alt="Moohub Logo"
                loading="lazy"
              />
            </a>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <a href="/" className={path === "/" ? css.active : css.regular}>
              Home
            </a>

            <a
              href={path === "/" ? "#services" : "/#services"}
              className={path === "/services" ? css.active : css.regular}
            >
              Services
            </a>
            <a
              href="/products"
              className={path === "/products" ? css.active : css.regular}
            >
              Products
            </a>
            <a
              href="/about"
              className={path === "/about" ? css.active : css.regular}
            >
              About
            </a>
            <a
              href="/careers"
              style={{ marginRight: "2.5rem" }}
              className={path === "/careers" ? css.active : css.regular}
            >
              Careers
            </a>

            <a
              href="/contact"
              style={{ color: "white", textDecoration: "none" }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  borderRadius: "23px",
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                    radial-gradient(67.01% 171.56% at 69.9% 57.72%, #3f39a0 0%, #121439 100%);`,
                }}
                variant="contained"
              >
                Contact Us
              </Button>
            </a>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          anchor="left"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
};

export default Navbar;
